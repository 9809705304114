import AOS from "aos";
import "aos/dist/aos.css";
import { data } from "../data";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FiAlertTriangle, FiCheckCircle } from "react-icons/fi";
import { PiArrowCircleLeftThin, PiArrowCircleRightThin } from "react-icons/pi";

const InfoAlert = ({ showAlert, setShowAlert, setBlur }) => {
  const navigate = useNavigate();

  if (!showAlert) return null;

  setBlur(true);

  const handleConfirm = () => {
    setShowAlert(false);
    setBlur(false);
    navigate("/quiz-result");
  };

  return (
    <div
      id="deleteModal"
      tabIndex="-1"
      aria-hidden="true"
      className={`fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full ${
        showAlert ? "flex" : "hidden"
      }`}
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative p-4 text-center bg-[#004933] rounded-lg shadow sm:p-5">
          <button
            type="button"
            className="text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-[#004933] rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={() => {
              setShowAlert(false);
              setBlur(false);
            }}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <FiCheckCircle className="text-white w-14 h-14 mb-4 mt-2 mx-auto" />
          <p className="mb-4 text-white">
            Are you sure you want to submit this quiz?
          </p>
          <div className="flex justify-center items-center space-x-4">
            <button
              type="button"
              className="py-2 px-3 text-sm font-medium text-[#004933] bg-white rounded-lg border border-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-primary-300 focus:z-10"
              onClick={() => {
                setShowAlert(false);
                setBlur(false);
              }}
            >
              No, cancel
            </button>
            <button
              type="button"
              className="py-2 px-3 text-sm font-medium text-center text-white bg-[#007BFF] rounded-lg hover:bg-[#0056b3] focus:ring-4 focus:outline-none focus:ring-primary-300"
              onClick={handleConfirm}
            >
              Yes, I'm sure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const NullAlert = ({ nullAlert, setNullAlert, setBlur }) => {
  if (!nullAlert) return null;

  setBlur(true);

  return (
    <div
      id="deleteModal"
      tabIndex="-1"
      aria-hidden="true"
      className={`fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full ${
        nullAlert ? "flex" : "hidden"
      }`}
    >
      <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative p-4 text-center bg-[#004933] rounded-lg shadow sm:p-5">
          <button
            type="button"
            className="text-white absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-[#004933] rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={() => {
              setNullAlert(false);
              setBlur(false);
            }}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <FiAlertTriangle className="text-white w-14 h-14 mb-5 mx-auto" />
          <p className="mb-4 text-white">
            Please answer the questions before proceeding
          </p>
        </div>
      </div>
    </div>
  );
};

export const QuestionCard = ({ currentQuestion, setCurrentQuestion }) => {
  AOS.init();

  const [selectedCount, setSelectedCount] = useState(0);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [nullAlert, setNullAlert] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [startQuiz, setStartQuiz] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const lengthOptions = data[currentQuestion]?.options?.length;
  const isFullWidth = data[currentQuestion]?.isFullWidth;

  useEffect(() => {
    const storedName = localStorage.getItem("name");
    const storedAge = localStorage.getItem("age");
    if (storedName) setName(storedName);
    if (storedAge) setAge(storedAge);
  }, []);

  useEffect(() => {
    const currentQuestionKey = `question_${currentQuestion}`;
    const savedAnswer = localStorage.getItem(currentQuestionKey);
    if (savedAnswer) {
      const { answer, answers } = JSON.parse(savedAnswer);
      if (data[currentQuestion]?.type === "checkbox") {
        setSelectedOptions([answers]);
      } else {
        setSelectedOption(answer);
      }
    } else {
      setSelectedOption(null);
      setSelectedOptions([]);
    }
  }, [currentQuestion]);

  const nextPage = () => {
    if (data[currentQuestion]?.type === "checkbox") {
      if (selectedOptions.length < 3) {
        setNullAlert(true);
        return;
      }

      const currentQuestionKey = `question_${currentQuestion}`;
      localStorage.setItem(
        currentQuestionKey,
        JSON.stringify({
          question: data[currentQuestion]?.question,
          answers: selectedOptions,
        })
      );
    } else if (selectedOption === null) {
      setNullAlert(true);
      return;
    } else {
      const currentQuestionKey = `question_${currentQuestion}`;
      localStorage.setItem(
        currentQuestionKey,
        JSON.stringify({
          question: data[currentQuestion]?.question,
          answer: selectedOption,
        })
      );
    }

    if (currentQuestion + 1 < data.length) {
      setCurrentQuestion(currentQuestion + 1);
      window.scrollTo(0, 0);
    } else {
      setShowAlert(true);
    }
  };

  const prevPage = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedCount(0);
      setSelectedOptions(null);
      localStorage.removeItem(`question_9`);
    }
  };

  const handleCheckbox = (e) => {
    const checkboxId = e.target.id;

    if (e.target.checked) {
      if (selectedCount < 3) {
        setSelectedCount(selectedCount + 1);
        setSelectedOptions((prev) => [...prev, checkboxId]);
      } else {
        e.preventDefault();
        return;
      }
    } else {
      setSelectedCount(selectedCount - 1);
      setSelectedOptions((prev) => prev.filter((id) => id !== checkboxId));
    }

    setDisabledCheckboxes((prev) =>
      selectedCount >= 3
        ? [...prev, checkboxId]
        : prev.filter((id) => id !== checkboxId)
    );
  };

  const handleOptionChange = (id) => {
    setSelectedOption(id);
  };

  const isSelected = (id) => selectedOption === id;

  const handleStartQuiz = () => {
    if (name === "" || age === "") {
      setNullAlert(true);
      return;
    } else {
      localStorage.setItem("name", name);
      localStorage.setItem("age", age);
      setStartQuiz(true);
    }
  };

  return (
    <>
      <InfoAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        setBlur={setIsBlurred}
      />
      <NullAlert
        nullAlert={nullAlert}
        setNullAlert={setNullAlert}
        setBlur={setIsBlurred}
      />
      <div
        style={{
          transition: "filter 0.3s ease",
          filter: isBlurred ? "blur(5px)" : "",
        }}
      >
        {!startQuiz ? (
          <div className="h-[81.5vh] md:h-auto">
            <p
              className="text-white frank text-3xl md:text-4xl lg:text-[52px] text-center mt-14"
              data-aos="zoom-in"
              data-aos-duration="400"
            >
              Silahkan Isi Nama & Umur
            </p>
            <div
              className="flex justify-center mt-7 mx-[12px] xl:mx-[21rem]"
              data-aos="fade-down"
              data-aos-duration="700"
            >
              <div className="block w-[35rem] px-4 sm:px-8 pb-7 sm:pb-12 pt-4 sm:pt-6 bg-white rounded-[8px]">
                <div className="mt-5">
                  <h2 className="text-sm md:text-lg font-medium text-gray-800">
                    Nama
                  </h2>
                  <input
                    type="text"
                    id="nama"
                    required
                    placeholder="Nama"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#004933] focus:border-[#004933] block w-full p-2.5"
                  />
                </div>
                <div className="mt-5">
                  <h2 className="text-sm md:text-lg font-medium text-gray-800">
                    Umur
                  </h2>
                  <input
                    type="number"
                    id="umur"
                    required
                    placeholder="Umur"
                    value={age}
                    min="0"
                    onChange={(e) => setAge(e.target.value)}
                    className="mt-1 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#004933] focus:border-[#004933] block w-full p-2.5"
                  />
                </div>
                <button
                  type="button"
                  onClick={handleStartQuiz}
                  className="mt-6 w-full text-white bg-[#004933] font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p
              className={`text-white frank 
                  text-3xl md:text-4xl lg:text-[52px] text-center my-9 mx-5`}
            >
              {data[currentQuestion]?.question}
            </p>
            {data[currentQuestion]?.desc ? (
              <p
                className={`text-white frank 
              text-xl md:text-2xl lg:text-[30px] text-center -mt-3 mb-11 mx-5`}
              >
                {data[currentQuestion]?.desc}
              </p>
            ) : null}
            {data[currentQuestion]?.type === "option" && (
              <div
                className={`overflow-hidden md:overflow-scroll block md:flex items-center justify-center mt-5 ${
                  lengthOptions > 2
                    ? "justify-between gap-5"
                    : "justify-center sm:justify-center"
                } ${lengthOptions > 2 ? "" : "gap-5 lg:gap-12"} ${
                  lengthOptions > 3
                    ? "xl:mx-28 mx-5"
                    : "xl:mx-[15rem] lg:mx-32 mx-5"
                }
                `}
              >
                {data[currentQuestion]?.options?.map((option) => (
                  <label
                    key={option?.id}
                    className="relative cursor-pointer sm:min-w-[255px] sm:max-w-[350px]"
                  >
                    <input
                      type="radio"
                      name={`question_${currentQuestion}`}
                      value={option?.id}
                      id={option?.id}
                      className="absolute opacity-0"
                      checked={isSelected(option?.id)}
                      onChange={() => handleOptionChange(option?.id)}
                    />
                    <div
                      className={`block w-full md:max-w-xs md:w-64 bg-white border rounded-t-[20px] ${
                        [1, 2, 5, 6, 7, 8].includes(currentQuestion)
                          ? "py-0"
                          : "py-20"
                      } ${
                        [2, 6, 7, 8].includes(currentQuestion)
                          ? "border-neutral-50"
                          : "border-gray-900"
                      } ${
                        isSelected(option?.id)
                          ? "border-2 border-[#004933]"
                          : ""
                      }`}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${option?.img}`}
                        alt={option?.title}
                        className={`${isFullWidth ? "w-full" : "mx-auto"} ${
                          [2, 5, 6, 7, 8].includes(currentQuestion)
                            ? "rounded-t-[20px]"
                            : ""
                        }`}
                      />
                    </div>
                    <div
                      className={`block w-full md:max-w-xs md:w-64 mb-5 md:mb-0 bg-white p-5 border-x border-b rounded-b-[20px] ${
                        [2, 6, 7, 8].includes(currentQuestion)
                          ? "border-neutral-50"
                          : "border-gray-900"
                      }`}
                    >
                      <div className="text-center">
                        <h2 className="text-xl font-semibold text-gray-800">
                          {option?.title}
                        </h2>
                        <p className="text-base mt-1 font-medium text-gray-500">
                          {option?.desc}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`absolute inset-0 flex items-center justify-center ${
                        isSelected(option?.id)
                          ? "border-2 border-[#004933]"
                          : ""
                      }`}
                      style={{
                        borderRadius: "20px",
                        pointerEvents: "none",
                        background: isSelected(option?.id)
                          ? "#00493389"
                          : "",
                        boxShadow: isSelected(option?.id)
                          ? "0 4px 30px rgba(0, 0, 0, 0.1)"
                          : "",
                        backdropFilter: isSelected(option?.id)
                          ? "blur(0.06px)"
                          : "",
                      }}
                    />
                  </label>
                ))}
              </div>
            )}
            {data[currentQuestion]?.type === "checkbox" && (
              <div className="flex mt-5 justify-center mx-3">
                <ul className="w-[32rem] text-base font-medium text-gray-900 bg-white rounded-lg p-6">
                  {data[currentQuestion]?.options?.map((option) => (
                    <div key={option?.id}>
                      <li className="w-full rounded-t-lg">
                        <div className="flex items-center ps-3">
                          <input
                            id={option?.id}
                            type="checkbox"
                            value=""
                            onClick={(e) => handleCheckbox(e)}
                            // checked={selectedOptions.includes(option?.id)}
                            className="w-4 h-4 text-[#004933] bg-gray-100 rounded focus:ring-[#004933] focus:ring-2"
                            disabled={disabledCheckboxes.includes(option?.id)}
                          />
                          <label
                            htmlFor={option?.title}
                            className="w-full py-[0.6rem] ms-3 text-md font-medium text-gray-900"
                          >
                            {option?.title}
                          </label>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            <div className="mt-4 flex items-center justify-center">
              <PiArrowCircleLeftThin
                size={70}
                onClick={prevPage}
                className={`cursor-pointer ${
                  currentQuestion === 0 ? "text-gray-400" : "text-white"
                }`}
              />
              <PiArrowCircleRightThin
                size={70}
                onClick={nextPage}
                className="cursor-pointer text-white"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionCard;
